import React from "react";

class Contact extends React.Component {
  componentDidMount() {
    document.title = "Contact";
  }

  render() {
    return <div>Contact</div>;
  }
}

export default Contact;
